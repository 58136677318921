import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { ListItem } from '../components/CustomStyles/Typography';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
    margin: '1rem 0',
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	header: {
		margin: '2rem 0 1rem',
    fontWeight: 700,
	},
  subHeader: {
		margin: '1rem 0 0 !important',
    fontWeight: 700,
	},
  bullet: {
    padding: '.5rem 0',
  },
}));

const SupportedDevicesPage = ({ data, location }) => {
	const supported = data.supported.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const { hero, _rawPageContent, metaTitle, metaDescription } = supported;
  const med = useMediaQuery('(max-width: 960px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Container style={{ marginBottom: '4rem' }}>
				<PortableText
					content={_rawPageContent}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								className={classes.header}
								color='primary'>
								{children}
							</Typography>
						),
						h3: ({ children }) => (
							<Typography
								variant='h3'
								className={classes.subHeader}
								color='primary'>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
              <Typography
                variant='body1'
                color="primary"
                style={{ fontWeight: 700 }}>
                {children}
              </Typography>
            ),
						normal: ({ children }) => (
							<Typography variant='body1' className={classes.text}>
								{children}
							</Typography>
						),
						li: ({ children }) => (
							<Grid item>
								<ListItem>
									<li style={{ padding: '.5rem 0' }}>{children}</li>
								</ListItem>
							</Grid>
						),
					}}
				/>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.pestpac.com/l/1042281/2023-10-12/65r4q8'
					/>
				) : null}
			</Container>
			<div style={{ marginTop: med ? '12rem' : '16rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query SupportedDevicesPageQuery {
		supported: allSanitySupportedDevices {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawContent
          }
          _rawPageContent
        }
      }
    }
	}
`;

export default SupportedDevicesPage;
